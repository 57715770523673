import React from 'react';
import Usluga from './Usluga';

const Uslugi = ({ usluga, uslugiState, doZaplaty, addZaplata }) => {
  let counter = [];
  for (let _ = 0; _ < usluga; _++) {
    counter.push(1);
  }
  return counter.map((com, ind) => {
    return (
      <Usluga
        key={ind}
        com={com}
        ind={ind}
        state={uslugiState[ind]}
        setDoZaplaty={addZaplata}
      />
    );
  });
};

export default Uslugi;
