import React from 'react';
import Divider from './Divider';
import { FiPlusCircle } from 'react-icons/fi';
import { FiMinusCircle } from 'react-icons/fi';
import { useState, useRef } from 'react';
import '../index.css';
import Uslugi from './Uslugi';
import {
  Page,
  Font,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import font from '../assets/fonts/Alegreya-Regular.ttf';

export default function Form() {
  let today = new Date().toISOString().slice(0, 10);

  const [usluga, setUsluga] = useState(1);
  const [doZaplaty, setDoZaplaty] = useState([0]);
  const [fileName, setFileName] = useState('numer_faktury');
  const [ready, setReady] = useState(false);
  const [fakturaData, setFakturaData] = useState({
    numer_faktury: '',
    data_wystawienia: today,
    data_wykonania: today,
    sprzedawca_nazwa: 'Szmery Bajery Jakub Sojecki',
    sprzedawca_adres: 'Leszczyńskiej 18 m.38/39 93-347, Łódź',
    sprzedawca_nip: '729-27-36-101',
    sprzedawca_regon: '387455054',
    sprzedawca_rachunek: '02 1140 2004 0000 3302 8071 9053',
    nabywca_nazwa: '',
    nabywca_adres: '',
    nabywca_nip: '',
    nabywca_regon: '',
    do_zaplaty: '',
    slownie_do_zaplaty: '',
    sposob_zaplaty: 'Przelew',
    termin_zaplaty: today,
  });
  const [uslugaState, setUslugaState] = useState([
    {
      uslugaNazwa: '',
      kwota_netto: '',
      kwota_brutto: '',
      liczba: '',
      lacznie: '',
    },
  ]);

  let downloadlink = useRef();

  // console.log(fakturaData, uslugaState);

  let addUsluga = () => {
    setUsluga(usluga + 1);
    setUslugaState([
      ...uslugaState,
      {
        uslugaNazwa: '',
        kwota_netto: '',
        kwota_brutto: '',
        liczba: '',
        lacznie: '',
      },
    ]);
  };
  let removeUsluga = () => {
    if (usluga > 1) {
      setUsluga(usluga - 1);
    } else {
      // setMessage({ message: { ...message, show: true } });
    }
  };

  let formSubmitted = (e) => {
    e.preventDefault();
  };

  let handleChange = (evt) => {
    const { name, value } = evt.target;
    // console.log(uslugaState);
    return setFakturaData({ ...fakturaData, [name]: value });
  };

  let getTotal = () => {
    // console.log('hello');
    document
      .getElementsByClassName('uslugaSuma')
      .reduce((prev, curr) => prev + curr);
  };

  const PDFDocument = () => {
    // console.log(fakturaData.numer_faktury, uslugaState);

    // setFakturaData({ ...fakturaData, do_zaplaty: 2 });

    Font.register({ family: 'Alegreya', src: font });

    const styles = StyleSheet.create({
      page: {
        fontFamily: 'Alegreya',
      },
      header: {
        color: '#000',
        marginRight: 10,
        fontSize: '9px',
        marginTop: '2px',
        fontWeight: 700,
      },
      bigHeader: {
        fontSize: '11px',
        fontWeight: 800,
        marginBottom: '10px',
      },
      tableCell: {
        border: '1px solid black',
        // flexGrow: 1,
        flexBasis: '100%',
        height: '100%',
        flexWrap: 'wrap',
        fontSize: '9px',
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: '2%',
        paddingBottom: '15px',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
      },
      rowRight: {},
    });

    return (
      <Document language='polish'>
        <Page size='A4' style={styles.page}>
          <View
            style={{
              marginTop: '10%',
              fontSize: '12px',
              color: '#333',
              marginLeft: '4%',
              marginRight: '4%',
              display: 'flex',
            }}>
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '50%',
                marginBottom: '5%',
              }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text>Data wystawienia:</Text>
                <Text>{fakturaData.data_wystawienia}</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text>Data wykonania usługi:</Text>
                <Text>{fakturaData.data_wykonania}</Text>
              </View>
            </View>
            <View>
              <Text
                style={{
                  fontSize: '24px',
                  textAlign: 'center',
                  marginBottom: '8%',
                }}>
                {fakturaData.numer_faktury}
              </Text>
            </View>
            <View style={[styles.row, { marginBottom: '10%' }]}>
              <View
                style={[styles.column, { marginRight: '3%', width: '50%' }]}>
                <Text style={styles.bigHeader}>SPRZEDAWCA:</Text>
                <View>
                  <View style={styles.row}>
                    <Text style={styles.header}>NAZWA:</Text>
                    <Text style={{ right: 0 }}>
                      {fakturaData.sprzedawca_nazwa}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.header}>ADRES:</Text>
                    <Text>{fakturaData.sprzedawca_adres}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={[styles.header]}>NIP:</Text>
                    <Text style={{ letterSpacing: '1px' }}>
                      {fakturaData.sprzedawca_nip}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={[styles.header]}>REGON:</Text>
                    <Text style={{ letterSpacing: '1px' }}>
                      {fakturaData.sprzedawca_regon}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.header}>NR RACHUNKU:</Text>
                    <Text>{fakturaData.sprzedawca_rachunek}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.column, { marginLeft: '3%', width: '50%' }]}>
                <Text style={styles.bigHeader}>NABYWCA:</Text>
                <View>
                  <View style={[styles.row]}>
                    <Text style={styles.header}>NAZWA:</Text>
                    <Text>{fakturaData.nabywca_nazwa}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.header}>ADRES:</Text>
                    <Text>{fakturaData.nabywca_adres}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.header}>NIP:</Text>
                    <Text style={{ letterSpacing: '1px' }}>
                      {fakturaData.nabywca_nip}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.header}>REGON:</Text>
                    <Text style={{ letterSpacing: '1px' }}>
                      {fakturaData.nabywca_regon}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View>
              <View style={[styles.row]}>
                <Text style={[styles.tableCell, { flexBasis: '50%' }]}>
                  L.P
                </Text>
                <Text style={[styles.tableCell, { flexBasis: '400%' }]}>
                  NAZWA
                </Text>
                <Text style={styles.tableCell}>CENA JEDN. NETTO</Text>
                <Text style={styles.tableCell}>LICZBA</Text>
                <Text style={styles.tableCell}>WARTOŚĆ NETTO</Text>
                <Text style={styles.tableCell}>KWOTA PODATKU</Text>
                <Text style={styles.tableCell}>WARTOŚĆ BRUTTO</Text>
              </View>
              {uslugaState.map((el, ind) => {
                return (
                  <View key={ind} style={[styles.row]}>
                    <Text style={[styles.tableCell, { flexBasis: '50%' }]}>
                      {parseInt(ind) + 1}
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        { flexBasis: '400%', fontSize: '10px' },
                      ]}>
                      {el.uslugaNazwa}
                    </Text>
                    <Text style={styles.tableCell}>
                      {parseFloat(el.kwota_netto).toFixed(2)}
                    </Text>
                    <Text style={styles.tableCell}>{el.liczba}</Text>
                    <Text style={styles.tableCell}>
                      {(
                        parseFloat(el.liczba) * parseFloat(el.kwota_netto)
                      ).toFixed(2)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {(
                        parseFloat(el.liczba) *
                        parseFloat(el.kwota_netto) *
                        0.23
                      ).toFixed(2)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {parseFloat(el.lacznie).toFixed(2)}
                    </Text>
                  </View>
                );
              })}
              <View style={styles.row}>
                <Text
                  style={[
                    {
                      border: '1px solid black',
                      flexBasis: '650%',
                      textAlign: 'right',
                      fontSize: '9px',
                      alignSelf: 'center',
                      paddingTop: '2%',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      color: '#000',
                    },
                  ]}>
                  RAZEM
                </Text>
                <Text style={[styles.tableCell]}>
                  {(parseFloat(doZaplaty) / 1.23).toFixed(2)}
                </Text>
                <Text style={[styles.tableCell]}>
                  {((parseFloat(doZaplaty) / 1.23) * 0.23).toFixed(2)}
                </Text>
                <Text style={[styles.tableCell]}>
                  {parseFloat(doZaplaty).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={{ marginTop: '10%' }}>
              <View style={styles.row}>
                <Text style={styles.header}>DO ZAPŁATY:</Text>
                <Text style={{ width: '80%', textAlign: 'center' }}>
                  {doZaplaty} PLN
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.header}>SŁOWNIE DO ZAPŁATY:</Text>
                <Text style={{ width: '80%', textAlign: 'center' }}>
                  {fakturaData.slownie_do_zaplaty}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.header]}>SPOSÓB ZAPŁATY:</Text>
                <Text style={{ width: '80%', textAlign: 'center' }}>
                  {fakturaData.sposob_zaplaty}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.header}>TERMIN ZAPŁATY:</Text>
                <Text style={{ width: '80%', textAlign: 'center' }}>
                  {fakturaData.termin_zaplaty}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <section className='w-[96%] ml-[2%] lg:w-4/5 lg:ml-[10%] xl:w-3/5 xl:ml-[20%] lg:px-[8%] py-[8%]'>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='mt-5 md:mt-0 md:col-span-3'>
            <form action='#' method='POST' onSubmit={formSubmitted}>
              <div className='shadow overflow-hidden sm:rounded-md px-12'>
                <h1 className='font-semibold underline text-3xl text-center pt-8 font-mono'>
                  Generator Faktur
                </h1>
                <div className='px-4 py-5 bg-white sm:p-6'>
                  <div className='grid grid-cols-6 gap-6'>
                    <Divider heading='Nagłówek' />
                    <div className='col-span-6 sm:col-span-4 sm:col-end-6'>
                      <label htmlFor='numer_faktury' className='formLabel'>
                        Numer Faktury
                      </label>
                      <input
                        type='text'
                        name='numer_faktury'
                        id='numer_faktury'
                        className='formInput'
                        required={true}
                        onChange={(e) => {
                          setFileName(e.target.value);
                          handleChange(e);
                        }}
                      />
                    </div>

                    <div className='col-span-3 sm:col-span-2 sm:col-end-7'>
                      <label htmlFor='data_wystawienia' className='formLabel'>
                        Data Wystawienia
                      </label>
                      <input
                        type='date'
                        name='data_wystawienia'
                        id='data_wystawienia'
                        defaultValue={today}
                        className='formInput'
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div className='col-span-3 sm:col-span-2 sm:col-end-7'>
                      <label htmlFor='data_wykonania' className='formLabel'>
                        Data Wykonania
                      </label>
                      <input
                        type='date'
                        name='data_wykonania'
                        id='data_wykonania'
                        defaultValue={today}
                        className='formInput'
                        onChange={handleChange}
                        required={true}
                      />
                    </div>

                    <div className='sm:col-span-3 sm:col-end-4 flex flex-col'>
                      <Divider heading='Sprzedawca' />

                      <div className='col-span-6 sm:col-span-6 my-1'>
                        <label htmlFor='sprzedawca_nazwa' className='formLabel'>
                          Nazwa
                        </label>
                        <input
                          type='text'
                          name='sprzedawca_nazwa'
                          id='sprzedawca_nazwa'
                          defaultValue='Szmery Bajery Jakub Sojecki'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-6 my-1'>
                        <label htmlFor='sprzedawca_adres' className='formLabel'>
                          Adres
                        </label>
                        <input
                          type='text'
                          name='sprzedawca_adres'
                          id='sprzedawca_adres'
                          defaultValue='Leszczyńskiej 18 m.38/39 93-347, Łódź'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-2 my-1'>
                        <label htmlFor='sprzedawca_nip' className='formLabel'>
                          NIP
                        </label>
                        <input
                          type='text'
                          name='sprzedawca_nip'
                          id='sprzedawca_nip'
                          defaultValue='729-27-36-101'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-2 my-1'>
                        <label htmlFor='sprzedawca_regon' className='formLabel'>
                          REGON
                        </label>
                        <input
                          type='text'
                          name='sprzedawca_regon'
                          id='sprzedawca_regon'
                          defaultValue='387455054'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-2 my-1'>
                        <label
                          htmlFor='sprzedawca_rachunek'
                          className='formLabel'>
                          Nr rachunku
                        </label>
                        <input
                          type='text'
                          name='sprzedawca_rachunek'
                          id='sprzedawca_rachunek'
                          defaultValue='02 1140 2004 0000 3302 8071 9053'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-3 sm:col-end-7 flex flex-col'>
                      <Divider heading='Nabywca' />

                      <div className='col-span-6 sm:col-span-6 my-1'>
                        <label htmlFor='nabywca_nazwa' className='formLabel'>
                          Nazwa
                        </label>
                        <input
                          type='text'
                          name='nabywca_nazwa'
                          id='nabywca_nazwa'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                          placeholder='nazwa'
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-6 my-1'>
                        <label htmlFor='nabywca_adres' className='formLabel'>
                          Adres
                        </label>
                        <input
                          type='text'
                          name='nabywca_adres'
                          id='nabywca_adres'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-2 my-1'>
                        <label htmlFor='nabywca_nip' className='formLabel'>
                          NIP
                        </label>
                        <input
                          type='text'
                          name='nabywca_nip'
                          id='nabywca_nip'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 sm:col-span-2 my-1'>
                        <label htmlFor='nabywca_regon' className='formLabel'>
                          REGON
                        </label>
                        <input
                          type='text'
                          name='nabywca_regon'
                          id='nabywca_regon'
                          className='formInput'
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                    <Divider heading='Usługi' />
                    <div className='col-span-6 flex justify-center items-center cursor-pointer'>
                      <div
                        onClick={addUsluga}
                        className=' text-green-600 text-2xl'>
                        <FiPlusCircle />
                      </div>
                      <div
                        onClick={removeUsluga}
                        className=' text-green-600 text-2xl'>
                        <FiMinusCircle />
                      </div>
                    </div>

                    <Uslugi
                      usluga={usluga}
                      uslugiState={uslugaState}
                      zaplata={doZaplaty}
                      addZaplata={setDoZaplaty}
                    />

                    <Divider heading='Stopka' />

                    <div className='col-span-6 sm:col-span-3 sm:col-end-4'>
                      <label htmlFor='do_zaplaty' className='formLabel'>
                        Do Zapłaty
                      </label>
                      <div id='do_zaplaty' className='formInput'>
                        <span id='do_zaplaty_wartosc'>{doZaplaty}</span> PLN
                      </div>
                    </div>

                    <div className='col-span-6 sm:col-span-6 '>
                      <label htmlFor='slownie_do_zaplaty' className='formLabel'>
                        Słownie do Zapłaty
                      </label>
                      <input
                        type='text'
                        name='slownie_do_zaplaty'
                        id='slownie_do_zaplaty'
                        className='formInput'
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div className='col-span-6 sm:col-span-3 sm:col-end-4'>
                      <label htmlFor='sposob_zaplaty' className='formLabel'>
                        Sposób Zapłaty
                      </label>
                      <input
                        type='text'
                        name='sposob_zaplaty'
                        id='sposob_zaplaty'
                        className='formInput'
                        defaultValue='Przelew'
                        onChange={handleChange}
                        required={true}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3 sm:col-end-4'>
                      <label htmlFor='termin_zaplaty' className='formLabel'>
                        Termin Zapłaty
                      </label>
                      <input
                        type='date'
                        name='termin_zaplaty'
                        id='termin_zaplaty'
                        defaultValue={today}
                        className='formInput'
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 mt-10 mb-4'>
                  <button
                    type='submit'
                    className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                      ready ? '' : 'opacity-50 cursor-not-allowed'
                    }`}>
                    <PDFDownloadLink
                      document={PDFDocument()}
                      fileName={`${fileName}.pdf`}>
                      {({ blob, url, loading, error }) => {
                        if (loading) {
                          setReady(false);
                          return 'Generowanie pliku...';
                        } else {
                          setReady(true);
                          return 'Pobierz plik';
                        }
                      }}
                    </PDFDownloadLink>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
