import React from 'react';

const Usluga = ({ ind, state, setDoZaplaty }) => {
  let handleChange = (e) => {
    let id = e.target.name.replace('usluga_', '').split('-')[1];
    state.uslugaNazwa = document.getElementById(`uslugaNazwa-${id}`).value;
    state.kwota_netto = document.getElementById(
      `usluga_kwota_netto-${id}`
    ).value;
    state.kwota_brutto = document.getElementById(
      `usluga_kwota_brutto-${id}`
    ).value;
    state.liczba = document.getElementById(`usluga_liczba-${id}`).value;
    state.lacznie = document.getElementById(`usluga_lacznie-${id}`).innerText;
  };

  let calcValue = (e) => {
    let possibleTypes = ['netto', 'brutto'];
    let val = e.target.value;
    // console.log(val);
    let data = e.target.id.replace('usluga_kwota_', '').split('-');
    let type = data[0];
    let index = data[1];
    possibleTypes.forEach((targetType) => {
      if (targetType !== type) {
        if (targetType === 'brutto') {
          document.getElementById(`usluga_kwota_${targetType}-${index}`).value =
            (parseFloat(val) * 1.23).toFixed(2);
        } else {
          document.getElementById(`usluga_kwota_${targetType}-${index}`).value =
            (parseFloat(val) / 1.23).toFixed(2);
        }
      }
    });
    calcLokalnaSuma(index);
  };

  let calcLokalnaSuma = (ind) => {
    let index;
    if (typeof ind === 'string') {
      index = ind;
    } else {
      index = ind.target.id.replace('usluga_liczba-', '');
    }

    let lokalnaSuma =
      parseFloat(
        document.getElementById(`usluga_kwota_brutto-${index}`).value
      ).toFixed(2) *
      parseFloat(
        document.getElementById(`usluga_liczba-${index}`).value
      ).toFixed(2);
    document.getElementById(`usluga_lacznie-${index}`).innerText = lokalnaSuma;
    let sumy = document.getElementsByClassName('uslugaSuma');
    sumy = Array.prototype.map.call(sumy, (sum) => parseFloat(sum.innerText));
    let lacznaSuma = sumy.reduce((prevVal, curVal) => prevVal + curVal);

    setDoZaplaty(lacznaSuma);
  };
  return (
    <div key={ind} className='col-span-6 grid grid-cols-6 gap-6'>
      <div className='col-span-6 sm:col-span-6'>
        <label htmlFor='nazwa_uslugi' className='formLabel'>
          Nazwa usługi
        </label>
        <input
          type='text'
          name={`uslugaNazwa-${ind}`}
          id={`uslugaNazwa-${ind}`}
          className='formInput'
          onChange={handleChange}
          required={true}
        />
      </div>

      <div className='col-span-6 sm:col-span-2'>
        <label htmlFor='usluga_kwota' className='formLabel'>
          Kwota netto
        </label>
        <input
          type='number'
          name={`usluga_kwota_netto-${ind}`}
          id={`usluga_kwota_netto-${ind}`}
          className='formInput'
          onChange={(e) => {
            calcValue(e);
            handleChange(e);
          }}
          step='.01'
          required={true}
        />
      </div>

      <div className='col-span-6 sm:col-span-2'>
        <label htmlFor='usluga_kwota' className='formLabel'>
          Kwota brutto
        </label>
        <input
          type='number'
          name={`usluga_kwota_brutto-${ind}`}
          id={`usluga_kwota_brutto-${ind}`}
          className='formInput'
          onChange={(e) => {
            calcValue(e);
            handleChange(e);
          }}
          step='.01'
          required={true}
        />
      </div>

      <div className='col-span-6 sm:col-span-1'>
        <label htmlFor='usluga_liczba' className='formLabel'>
          Liczba
        </label>
        <input
          type='number'
          name={`usluga_liczba-${ind}`}
          id={`usluga_liczba-${ind}`}
          className='formInput'
          defaultValue={1}
          min={1}
          onChange={(e) => {
            calcLokalnaSuma(e);
            handleChange(e);
          }}
          required={true}
        />
      </div>
      <div className='col-span-6 sm:col-span-1'>
        <label htmlFor='usluga_lacznie' className='formLabel'>
          Łącznie
        </label>
        <div
          id={`usluga_lacznie-${ind}`}
          className='formInput uslugaSuma'
          onChange={handleChange}>
          0
        </div>
      </div>
    </div>
  );
};

export default Usluga;
