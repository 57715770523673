import React from 'react';

const Divider = ({ heading }) => {
  return (
    <>
      <div className='col-span-6 mt-2'>
        <h3 className='text-lg font-medium text-gray-700 pl-12'>{heading}</h3>
      </div>
      <div className='col-span-6 mb-6 h-[2px] bg-gray-300'></div>
    </>
  );
};

export default Divider;
