import Form from './components/Form.jsx';

function App() {
  return (
    <main className='App'>
      <Form />
    </main>
  );
}

export default App;
